import React, { Component } from "react";

class LeaderBoardAd extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    const { adClient, adSlot } = this.props; // Destructuring props

    return (
      <div>
        {/* nujumaki */}
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client={adClient} // Using adClient prop here
          data-ad-slot={adSlot} // Using adSlot prop here
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    );
  }
}

export default LeaderBoardAd;
