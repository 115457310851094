import React, { useEffect, useState } from "react";
import firebase from "../../Firebase";
import { RMIUploader } from "react-multiple-image-uploader";
// import { v4 as uuidv4 } from "uuid";
import MultiSelect from "react-multi-select-component";
import Resizer from "react-image-file-resizer";

const options = [
  { label: "Landscape", value: "landscape" },
  { label: "Street", value: "street" },
  { label: "Product", value: "product" },
  { label: "Portrait", value: "portrait" },
  { label: "Black&White", value: "black and white" },
  { label: "Animal", value: "animal" },
  { label: "Flower", value: "flower" },
  // { label: "Landscape", value: "Landscape", disabled: true },
];

function UploadImages() {
  const [selected, setSelected] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [src, setSrc] = useState("");
  const [genre, setGenre] = useState([]);
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Japan");
  const [rating, setRating] = useState("");
  const [editActionName, setEditActionName] = useState("");
  const [dimensionType, setDimensionType] = useState("Landscape1");
  const [isFeatured, setIsFeatured] = useState(false);
  const [width, setWidth] = useState("3");
  const [height, setHeight] = useState("2");
  const [photoCount, setPhotoCount] = useState(0);
  const [id, setId] = useState("");

  const ref = firebase.firestore().collection("Photos");
  const db = firebase.firestore();
  const [progress, setProgress] = useState(0);

  function resizeUpload(file) {
    console.log("file:" + file.name);
    const smallLength = 1080;
    let w = parseInt(width);
    let h = parseInt(height);
    // console.log(width + " and " + height + " and " + dimensionType);
    var newWidth = 900;
    var newHeight = 400;
    var factor = 3;
    if (w < h) {
      newWidth = smallLength;
      factor = newWidth / w;
      newHeight = factor * h;
    } else if (h < w) {
      newHeight = smallLength;
      factor = newHeight / h;
      newWidth = factor * w;
    } else {
      newHeight = newWidth = smallLength;
    }

    try {
      Resizer.imageFileResizer(
        file,
        newWidth,
        newHeight,
        "JPEG",
        95,
        0,
        (uri) => {
          handleUpload(uri);
        },
        "file"
      );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPhotoCount();
  }, []);

  useEffect(() => {
    addPhotos();
  }, [src]);

  useEffect(() => {
    handleMultiSelect();
  }, [selected]);

  useEffect(() => {
    if (dimensionType === "Landscape1") {
      setWidth("3");
      setHeight("2");
    } else if (dimensionType === "Landscape2") {
      setWidth("4");
      setHeight("3");
    } else if (dimensionType === "Portrait1") {
      setWidth("2");
      setHeight("3");
    } else if (dimensionType === "Portrait2") {
      setWidth("3");
      setHeight("4");
    } else if (dimensionType === "Square") {
      setWidth("1");
      setHeight("1");
    } else if (dimensionType === "Cover") {
      setWidth("16");
      setHeight("9");
    } else if (dimensionType === "Wide") {
      setWidth("21");
      setHeight("9");
    }
  }, [dimensionType]);

  // function clearAll() {
  //   setTitle("");
  //   setDesc("");
  //   setSrc("");
  //   setGenre("");
  //   setPlace("");
  //   setCity("");
  //   setCountry("Japan");
  //   setRating("");
  //   setIsFeatured(false);
  //   setDimensionType("Landscape1");
  //   setVisible(false);
  // }

  function getPhotoCount() {
    const refCount = ref.doc("stats");
    refCount.onSnapshot(
      (docSnapshot) => {
        setPhotoCount(docSnapshot.data().photocount);
        console.log("getting Photo count:" + photoCount);
      },
      (err) => {
        console.log(`Encountered error while getting photo count: ${err}`);
      }
    );
  }

  function addPhotos() {
    console.log("inside addPhoto()");
    const newPhoto = {
      title,
      desc,
      src,
      id,
      genre,
      place,
      city,
      country,
      rating,
      isFeatured,
      width,
      height,
      editActionName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (newPhoto.src.length > 0) {
      console.log("inside addPhoto() adding photocount");
      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = db.batch();
      const refCount = ref.doc("stats");
      const refPhoto = ref.doc(newPhoto.id);
      batch.set(refPhoto, newPhoto);
      batch.set(refCount, { photocount: increment }, { merge: true });
      batch.commit();
    }
  }

  function handleMultiSelect() {
    var genraValue = [];
    selected.forEach((element) => {
      genraValue.push(element.value);
    });
    setGenre(genraValue);
  }

  const handleUpload = (image) => {
    console.log("file:" + image.name);
    const storageRef = firebase.storage().ref();
    setProgress(0);
    var uploadTask = storageRef.child(`ImageGallery/${image.name}`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setProgress(progress);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((fireBaseUrl) => {
          setId(image.name);
          setSrc(fireBaseUrl);
        });
      }
    );
  };

  //uploader methods
  const [visible, setVisible] = useState(false);
  const hideModal = () => {
    setVisible(false);
  };
  const onUpload = (data) => {
    setSelectedPhotos(data);
    let newCount = photoCount;

    for (var i = 0; i < data.length; i++) {
      const file = data[i].file;
      newCount += 1;
      console.log("new count ", newCount);
      //renameing file
      const newName = "MAN" + newCount + "." + file.type.split("/")[1];
      const myNewFile = new File([file], newName, {
        type: file.type,
      });
      console.log("Upload files", myNewFile);
      setPhotoCount(newCount);
      resizeUpload(myNewFile);
    }
    setProgress(0.0);
  };
  const onSelect = (data) => {
    console.log("Select files", data);
  };
  const onRemove = (id) => {
    console.log("Remove image id", id);
  };
  //

  return (
    <div className="App">
      <h1>Upload Photos</h1>
      <div class="row">
        <div class="col-sm">
          <h6 class="row formHeader">Title</h6>
          <input
            className="formElement row"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <h6 class="row formHeader">Genre</h6>
          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
          <h6 class="row formHeader">Country</h6>
          <input
            className="formElement row"
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
          <h6 class="row formHeader">Dimension Type</h6>
          <select
            className="formElement row"
            value={dimensionType}
            onChange={(e) => setDimensionType(e.target.value)}
          >
            <option value="Landscape1">Landscape (3*2)</option>
            <option value="Portrait1">Portrait (2*3)</option>
            <option value="Landscape2">Landscape (4*3)</option>
            <option value="Portrait2">Portrait (3*4)</option>
            <option value="Square">Square (1*1)</option>
            <option value="Cover">Cover (16*9)</option>
            <option value="Wide">Wide (21*9)</option>
          </select>
          <div class="row formHeader">
            <h6 class="formHeader">
              {" "}
              Featured{" "}
              <input
                className="checkbox"
                type="checkbox"
                checked={isFeatured}
                onChange={(e) => setIsFeatured(!isFeatured)}
              />
            </h6>
          </div>
        </div>

        <div class="col-sm">
          <h6 class="row formHeader">Place</h6>
          <input
            className="formElement row"
            type="text"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          />
          <h6 class="row formHeader">City</h6>
          <input
            className="formElement row"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <h6 class="row formHeader">Rating</h6>
          <input
            className="formElement row"
            type="number"
            value={rating}
            onChange={(e) => setRating(e.target.value)}
          />
          <h6 class="row formHeader">Description</h6>
          <textarea
            className="formElement row"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
          <h6 class="row formHeader">Photo Edit Action</h6>
          <textarea
            className="formElement row"
            value={editActionName}
            onChange={(e) => setEditActionName(e.target.value)}
          />
          {/* <button onClick={() => addPhotos()}>Submit</button> */}
        </div>
      </div>
      <div class="row">
        <br />
        <div class="col-sm">
          {/* <progress value={progress} max="100" /> */}
          {selectedPhotos.map((photo) => (
            <progress value={progress} max="100" />
          ))}
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <RMIUploader
            isOpen={visible}
            hideModal={hideModal}
            onSelect={onSelect}
            onUpload={onUpload}
            onRemove={onRemove}
            dataSources={selectedPhotos}
          />
        </div>
      </div>
    </div>
  );
}

export default UploadImages;
