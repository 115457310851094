import "../../App.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import React, { useEffect, useState } from "react";
import firebase from "../../Firebase";
const ref = firebase.firestore().collection("Photos");

export default function EditPhotos() {
  const [selected] = useState([]);
  const [photos, setPhotos] = useState([]);
  //REALTIME GET FUNCTION
  function getPhotos() {
    console.log("inside getPhoto");
    ref
      //.where('owner', '==', currentUserId)
      //.where('title', '==', 'School1') // does not need index
      //.where('score', '<=', 10)    // needs index
      //.orderBy('owner', 'asc')
      //.limit(3)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          items.push(data);
        });

        //console.log(items);
        items.pop();
        setPhotos(items);
      });
  }

  useEffect(() => {
    getPhotos();
    // eslint-disable-next-line
  }, []);

  //DELETE FUNCTION
  function deletePhoto(photo) {
    const storageRef = firebase.storage().ref();
    console.log("deleted name:" + photo.id);
    storageRef
      .child(`ImageGallery/${photo.id}`)
      .delete()
      .then(() => {
        ref
          .doc(photo.id)
          .delete()
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });

    console.log(selected);
  }

  return (
    <>
      <Navbar />
      <div class="container body-wrapper">
        <div>Total Count : {photos.length}</div>
        <div class="row tablerow">
          <div class="col-sm header">Photo</div>
          <div class="col-sm header">Photo Id</div>
          <div class="col-sm header">Title</div>
          <div class="col-sm header">isFeatured</div>
          <div class="col-sm header">Action</div>
        </div>

        {photos.map((photo) => (
          <div className="school row tablerow" key={photo.id}>
            <div class="col-sm">
              <img
                src={photo.src}
                alt={photo.title}
                width={photo.width * 30}
                height={photo.height * 30}
              ></img>
            </div>

            <div class="col-sm">{photo.id}</div>
            <div class="col-sm">{photo.title}</div>
            <div class="col-sm">{photo.isFeatured ? "Yes" : "No"}</div>
            <div class="col-sm">
              {" "}
              <button onClick={() => deletePhoto(photo)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}
