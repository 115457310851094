import "../App.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import AboutMe from "../Components/AboutMe";
import React from "react";

export default function Profile() {
  return (
    <>
      <Navbar />
      <div className="body-wrapper container">
        <AboutMe />
      </div>
      <Footer />
    </>
  );
}
