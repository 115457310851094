import React, { useState, useCallback, useEffect } from "react";
import Carousel, { Modal as ModalImg, ModalGateway } from "react-images";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import firebase from "./../../Firebase";
import Gallery from "react-photo-gallery";
import { Modal, Button } from "react-bootstrap";
import ImgCan from "../../Images/1can.jpeg";

export default function Photography() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [photos, setPhotos] = useState([]);
  const ref = firebase.firestore().collection("Photos");

  const [showPopup, setShowPopup] = useState(true);
  function handlePopup(isShow) {
    setShowPopup(isShow);
  }

  //REALTIME GET FUNCTION
  function getPhotos() {
    ref
      //.where('owner', '==', currentUserId)
      //.where('title', '==', 'School1') // does not need index
      //.where('score', '<=', 10)    // needs index
      .orderBy("rating", "desc")
      .limit(100)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          doc.height = 2;
          doc.weidth = 3;
          items.push(doc.data());
        });
        setPhotos(items);
      });
  }

  useEffect(() => {
    getPhotos();
    // eslint-disable-next-line
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <Navbar />
      <div className="body-wrapper container">
        {/* Popup */}
        <Modal
          show={showPopup}
          onHide={handlePopup}
          centered
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            {/* <FreePopup /> */}

            <div class="row popupContainer">
              <div class="col-sm col-lg-5">
                {/* <img
                  src="https://firebasestorage.googleapis.com/v0/b/manojgallary-d7e7f.appspot.com/o/ImageGallery%2FMAN29.JPEG?alt=media&token=422e7044-1216-4f53-8e7c-8f6ce89fa587"
                  className="imgPopup"
                ></img> */}
                <img src={ImgCan} className="imgPopup" />
              </div>
              <div class="col-sm col-lg-7">
                <div class="canvas">
                  <div class="row">
                    <h1 class="text-uppercase highlightedText">
                      {" "}
                      Use image for Free
                    </h1>
                  </div>
                  <div class="font-weight-bold normal">
                    <p>
                      For high resolution images, inbox me in ~
                      <a href="https://www.linkedin.com/in/shrestha-manoj/" target="_blank" rel="noopener noreferrer">Linkedin</a>.
                    </p>
                    <br />
                    <h5>
                      Editorial use, use on merchandise and in video productions
                      require visible credit.
                    </h5>
                  </div>
                  <div class="row popupContainer">
                    <Button
                      className="btn-block btnPopup"
                      onClick={() => {
                        setShowPopup(false);
                      }}
                    >
                      I Agree
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Popup End*/}

        <div className="fullbodycard">
          <Gallery photos={photos} onClick={openLightbox} margin={5} />
          <ModalGateway>
            {viewerIsOpen ? (
              <ModalImg onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </ModalImg>
            ) : null}
          </ModalGateway>
        </div>
      </div>
      <Footer />
    </div>
  );
}
