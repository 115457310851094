import React from "react";
import "../../App.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import UploadImages from "./UploadImage";

export default function UploadPage() {
  return (
    <>
      <Navbar />
      <div class="container body-wrapper">
        <UploadImages />
      </div>
      <Footer />
    </>
  );
}
