import React from "react";
import "./Footer.css";
import { useEffect } from "react";
import LeaderBoardAd from "../Components/LeaderBoardAd";

const Footer = () => {
  useEffect(() => {
    // Google add scense script added
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6780615883898279";
    script.async = true;
    document.body.appendChild(script);

    return () => {};
  }, []);
  return (
    <footer class="float-bottom bg-dark">
      <div class="text-center text-light footer">
        Copyright © 2023 | Shrestha Apps
        <br />
        Built using React and Bootstrap
        <br />
        All rights reserved | V.1.0.7
      </div>
      <LeaderBoardAd adClient="ca-pub-6780615883898279" adSlot="7757082732" />
    </footer>
  );
};

export default Footer;
