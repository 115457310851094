import React from "react";
import "../App.css";
import "./HeroSection.css";
import bg from "../Images/3.png";
import bg1 from "../Images/4.png";
import bg2 from "../Images/5.png";

// omponentDidMount => () {
//   $(".carousel").carousel();
// }

function HeroSection() {
  return (
    // <div className="hero-container">
    //   <video src="./../Videos/video-1.mp4" autoPlay loop muted />
    //   <h1>Hi, I am Manoj!</h1>
    //   <h2>
    //     I'm a Mobile App Developer based in <strike>Kathmandu</strike>, Tokyo.
    //   </h2>
    //   <p>iOS Developer | UI / UX Enthusiast</p>
    //   <p>Photography is one more reason to Travel</p>
    // </div>

    // <div class="imgHeader">
    //   <h2>About Me</h2>
    // </div>

    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src={bg} alt="First slide" />
          <div class="carousel-caption d-none d-md-block"></div>
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src={bg1} alt="Second slide" />
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src={bg2} alt="Third slide" />
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  );
}

export default HeroSection;
