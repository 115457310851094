import React, { useState, useCallback, useEffect } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import firebase from "./../../Firebase";
import Gallery from "react-photo-gallery";

export default function PhotoLandscape() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [photos, setPhotos] = useState([]);
  const ref = firebase.firestore().collection("Photos");

  //REALTIME GET FUNCTION
  function getPhotos() {
    ref

      .where("genre", "array-contains", "landscape")
      //.where('title', '==', 'School1') // does not need index
      //.where('score', '<=', 10)    // needs index
      //.orderBy('owner', 'asc')
      //.limit(3)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          doc.height = 2;
          doc.weidth = 3;
          items.push(doc.data());
        });
        console.log(items);
        setPhotos(items);
      });
  }

  useEffect(() => {
    getPhotos();
    // eslint-disable-next-line
  }, []);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <Navbar />
      <div className="body-wrapper container">
        <div className="fullbodycard">
        <Gallery photos={photos} onClick={openLightbox} margin={5}/>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
      <Footer />
    </div>
  );
}
