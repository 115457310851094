import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Hashrouter, Switch, Route } from "react-router-dom";
// import Home from "./Pages/Home";
import Profile from "./Pages/Profile";
import Blog from "./Pages/Blog";
import Travel from "./Pages/Travel";
import TaxAdvisor from "./Pages/IncomeTaxAdvisor";
// import TaxAdvisor from "./Pages/TaxAdvisor";

import Photography from "./Pages/Photography/Photography";
import PhotoFeatured from "./Pages/Photography/PhotoFeatured";
import PhotoLandscape from "./Pages/Photography/PhotoLandscape";
import PhotoStreet from "./Pages/Photography/PhotoStreet";
import PhotoPortrait from "./Pages/Photography/PhotoPortrait";
import UploadPage from "./Pages/Admin/UploadPage";
import EditPhotos from "./Pages/Admin/EditPhotos";
import ReactGa from "react-ga";
import React, { useEffect, useState } from "react";
import PacmanLoader from "react-spinners/CircleLoader";

import Privacy from "./Pages/MPC/Privacy";
import Share from "./Pages/MPC/Share";
import LiveScore from "./Pages/MPC/livescore";
import Ads from "./Pages/ads";

function App() {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);

  useEffect(() => {
    ReactGa.initialize("UA-201315269-1");
    //to report page view
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading">
          <PacmanLoader color={"#FFFFFF"} loading={loading} size={50} />
        </div>
      ) : (
        <Hashrouter>
          <Switch>
            {/* <Route path="/" exact component={Home} /> */}
            <Route path="/" exact component={Profile} />
            {/* <Route path='/' exact component={AllPhotos} /> */}
            <Route path="/profile" component={Profile} />
            <Route path="/blog" component={Blog} />
            <Route path="/travel" component={Travel} />
            <Route path="/photography" component={Photography} />
            <Route path="/photofeatured" component={PhotoFeatured} />
            <Route path="/photolandscape" component={PhotoLandscape} />
            <Route path="/photostreet" component={PhotoStreet} />
            <Route path="/photoportrait" component={PhotoPortrait} />
            <Route path="/editphotos" exact component={EditPhotos} />
            <Route path="/uploadphotos" exact component={UploadPage} />
            <Route path="/taxadvisor" exact component={TaxAdvisor} />
            <Route path="/MPC/privacypolicy" exact component={Privacy} />
            <Route path="/MPC/share" exact component={Share} />
            <Route path="/MPC" exact component={LiveScore} />
            <Route path="/adspage" exact component={Ads} />
          </Switch>
        </Hashrouter>
      )}
    </>
  );
}

export default App;
