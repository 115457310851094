import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../App";
import MPCLogo from "../../Images/MPCLogo.png";
import LeaderBoardAd from "../../Components/LeaderBoardAd";

const LiveScore = () => {
  const history = useHistory();
  const location = useLocation();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const gameKey = params.get("game");
    const redirectUrl = `https://marriagepointcalculator.netlify.app/#/RealTimeScorePage?gamekey=${gameKey}`;
    const timer = setTimeout(() => {
      redirectToGame(redirectUrl);
    }, 5000);

    // Clean up the timer when the component unmounts or when the timer should be reset
    return () => clearTimeout(timer);
  }, [history, location.search]);

  useEffect(() => {
    // Google add scense script added
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6780615883898279";
    script.async = true;
    document.body.appendChild(script);
    return () => {};
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clean up the timer when the component unmounts or when the countdown reaches 0
    return () => clearInterval(timer);
  }, []);

  function redirectToGame(redirectUrl) {
    window.location.href = redirectUrl;
  }

  return (
    <>
      <div class="body-wrapper container">
        <div class="centered-text">
          <h5>Generating live score board.</h5>
          <h5>Please wait ... {countdown}</h5>
          <LeaderBoardAd
            adClient="ca-pub-6780615883898279"
            adSlot="7757082732"
          />
        </div>
      </div>
    </>
  );
};

export default LiveScore;
