import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
// import ShresthaLogo from "../Images/ShresthaLogo.png";
import shresthaLogo from "../Images/ShresthaLogoSmall.png";

const Navbar = () => {
  const location = useLocation();

  let isHomePage = false;
  let isAboutMe = false;
  let isPhotography = false;
  let isTool = false;
  let isBlog = false;
  let isTravel = false;
  let isTaxAdvisor = false;

  function handleClick() {
    switch (location.pathname) {
      case "/":
        console.log("home found", location.pathname);
        isHomePage = false;
        isAboutMe = true;
        isPhotography = false;
        isTool = false;
        isBlog = false;
        isTravel = false;
        isTaxAdvisor = false;
        break;
      case "/profile":
        isHomePage = false;
        isAboutMe = true;
        isPhotography = false;
        isTool = false;
        isBlog = false;
        isTravel = false;
        isTaxAdvisor = false;
        break;
      case "/photography":
      case "/photofeatured":
      case "/PhotoLlndscape":
      case "/photostreet":
      case "/photoproduct":
      case "/photoportrait":
      case "/uploadphotos":
      case "/editphotos":
        isHomePage = false;
        isAboutMe = false;
        isPhotography = true;
        isTool = false;
        isBlog = false;
        isTravel = false;
        isTaxAdvisor = false;
        break;
      case "/taxadvisor":
        isHomePage = false;
        isAboutMe = false;
        isPhotography = false;
        isTool = true;
        isBlog = false;
        isTravel = false;
        isTaxAdvisor = false;
        break;
      case "/blog":
        isHomePage = false;
        isAboutMe = false;
        isPhotography = false;
        isTool = false;
        isBlog = true;
        isTravel = false;
        isTaxAdvisor = false;
        break;
      case "/travel":
        isHomePage = false;
        isAboutMe = false;
        isPhotography = false;
        isTool = false;
        isBlog = false;
        isTravel = true;
        isTaxAdvisor = false;
        break;
      case "/taxadvisor":
        isHomePage = false;
        isAboutMe = false;
        isPhotography = false;
        isTool = false;
        isBlog = false;
        isTravel = false;
        isTaxAdvisor = true;
        break;
      default:
        console.log("cannot find path", location.pathname);
    }
  }

  // console.log(
  //   "isHome =",
  //   isHomePage,
  //   isAboutMe,
  //   isPhotography,
  //   isBlog,
  //   isTravel,
  //   isTaxAdvisor
  // );

  // let [visitorCount, setVisitorCount] = useState(0);
  // function updateVisitorCount() {
  //   fetch(
  //     "https://api.countapi.xyz/update/shrestham.com.np/mygallery/?amount=1"
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setVisitorCount(res.value);
  //     });
  // }
  // useEffect(() => {
  //   updateVisitorCount();
  // }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark fixed-top">
      <div className="container">
        {/* <a className="navbar-brand" href="#">
          <img className="logo" src={logo} alt="logo.." />
        </a> */}
        <a href="/">
          <img class="logo" src={shresthaLogo} alt="Shrestha Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          {/* <span className="navbar-toggler-icon"></span> */}
          <FontAwesomeIcon icon={faBars} style={{ color: "#fff" }} />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <a
                className={isHomePage ? "nav-link active" : "nav-link"}
                href="/"
                onClick={handleClick()}
              >
                About me <span className="sr-only">(aboutme)</span>
              </a>
            </li> */}
            <li className="nav-item">
              <a
                className={isHomePage ? "nav-link active" : "nav-link"}
                href="/"
                onClick={handleClick()}
              >
                <span className="sr-only">(isHomePage)</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={isAboutMe ? "nav-link active" : "nav-link"}
                href="/profile"
                onClick={handleClick()}
              >
                About Me <span className="sr-only">(aboutme)</span>
              </a>
            </li>

            {/* <li className="nav-item">
              <a
                className={isPhotography ? "nav-link active" : "nav-link"}
                href="/Photography"
                onClick={handleClick()}
              >
                Photography <span className="sr-only">(Photography)</span>
              </a>
            </li> */}
            <li class="nav-item dropdown">
              <a
                className={
                  isPhotography
                    ? "nav-link dropdown-toggle active"
                    : "nav-link dropdown-toggle"
                }
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="photography"
              >
                Photography
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  class="dropdown-item"
                  href="/photography"
                  onClick={handleClick()}
                >
                  All
                </a>
                <a
                  class="dropdown-item"
                  href="/photofeatured"
                  onClick={handleClick()}
                >
                  Featured
                </a>
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  href="/photolandscape"
                  onClick={handleClick()}
                >
                  Landscape
                </a>
                <a
                  class="dropdown-item"
                  href="/photoportrait"
                  onClick={handleClick()}
                >
                  Portrait
                </a>
                <a
                  class="dropdown-item"
                  href="/photostreet"
                  onClick={handleClick()}
                >
                  Street
                </a>
              </div>
            </li>

            {/* <li className="nav-item">
              <a
                className={isTaxAdvisor ? "nav-link active" : "nav-link"}
                href="/taxadvisor"
                onClick={handleClick()}
              >
                Tax Advisor <span className="sr-only">(taxadvisor)</span>
              </a>
            </li> */}
            <li class="nav-item dropdown">
              <a
                className={
                  isTool
                    ? "nav-link dropdown-toggle active"
                    : "nav-link dropdown-toggle"
                }
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="tools"
              >
                Tools
              </a>
              <div class="dropdown-menu " aria-labelledby="navbarDropdown">
                <a
                  class="dropdown-item"
                  href="/taxadvisor"
                  onClick={handleClick()}
                >
                  Free Tax Advisor
                </a>
                <a
                  class="dropdown-item"
                  href="https://marriagepointcalculator.netlify.app/"
                  target="_blank"
                >
                  Marriage Point Calculator
                </a>
              </div>
            </li>

            {/* <li className="nav-item">
              <a className="visit">
                Visits: <strong>{Number(visitorCount / 2).toFixed(0)}</strong>
                <span className="sr-only">(Visitor's count)</span>
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a
                className={isBlog ? "nav-link active" : "nav-link"}
                href="/blog"
                id="blog"
                onClick={handleClick()}
              >
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a
                className={isTravel ? "nav-link active" : "nav-link"}
                href="/travel"
                id="travel"
                onClick={handleClick()}
              >
                Travel
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a
                className={isTravel ? "nav-link active" : "nav-link"}
                href="/travel"
                id="travel"
                onClick={handleClick()}
              >
                Travel
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
