import React from "react";
import { useEffect } from "react";
import LeaderBoardAd from "../Components/LeaderBoardAd";

const Ads = () => {
  useEffect(() => {
    // Google add scense script added
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6780615883898279";
    script.async = true;
    document.body.appendChild(script);
    return () => {};
  }, []);
  return (
    <>
      <LeaderBoardAd adClient="ca-pub-6780615883898279" adSlot="1366084143" />
      <LeaderBoardAd adClient="ca-pub-6780615883898279" adSlot="7757082732" />
    </>
  );
};

export default Ads;
