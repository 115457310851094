import React from "react";
import "../../App";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer"
import LeaderBoardAd from "../../Components/LeaderBoardAd";

export default function Privacy() {
    return (
        <>
            <Navbar />
            <h1>Travel</h1>
            <div class="container body-wrapper">
                <div class="card text-justify">
                    <div class="row">
                        <h5 class="text-left">Marriage Point Calculator Mobile App</h5>
                        <div class="row">
                            <div class="col-sm">
                                <h5 style={{ marginTop: "20px" }}>Privacy Policy</h5>
                                we are committed to protecting your privacy. This Privacy Policy explains how we use the information that we collect when you use our point calculation mobile app, Marriage Point Calculator.
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-sm">
                                <h6><b>Information We Collect</b></h6>
                                We do not collect any personal information or usage data when you use the App.
                            </div>
                        </div>

                        <br />
                        <div class="row">
                            <div class="col-sm">
                                <h6><b>Data Retention</b></h6>
                                We do not retain any information that we collect through the App. Data are saved inside your device locally and are only accessible with in you device.
                            </div>
                        </div>
                        <b></b>
                        <br />
                        <h5 style={{ marginTop: "20px" }}>Advertisement Policy</h5>
                        <div class="row">
                            <div class="col-sm"> In order to provide a better user experience and to support the development of our app, we may display advertisements within the app. This advertisement policy explains how and when these ads will be shown.
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-sm"> <b>Types of Ads: </b>The Marriage Point Calculator app may display banner ads, interstitial ads, and video ads. These ads may be displayed while using the app but will not hamper the usage of the app.
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-sm"><b>Advertising Partners: </b>The Marriage Point Calculator app may work with third-party advertising partners to display ads. These partners may also collect data through the app for the purpose of personalizing ads. <br />
                                <a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">Click here for Admob Policy.</a></div>
                        </div>
                        <br />
                        <h5 style={{ marginTop: "20px" }}>Changes to This Privacy Policy</h5>
                        <div class="row">
                            <div class="col-sm">We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review the Privacy Policy whenever you access the App to stay informed about our information practices and your choices.</div>
                        </div>
                        <br />
                        <h5 style={{ marginTop: "20px" }}>Contact Us</h5>
                        <div class="row">
                            <div class="col-sm">If you have any questions about this Privacy Policy or our information practices, please contact me through ~
                                <a href="https://www.linkedin.com/in/shrestha-manoj/" target="_blank" rel="noopener noreferrer">Linkedin</a>.
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <b>Last updated: Jan 6, 2023</b>
                    </div>
                </div>
                <LeaderBoardAd />
            </div>
            <Footer />
        </>
    );
}

