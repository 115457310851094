import React, { useEffect, useState } from "react";
import "../App.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Modal, Button } from "react-bootstrap";
import icoClose from "../Images/cancel.png";
import firebase from "../Firebase";
import LeaderBoardAd from "../Components/LeaderBoardAd";

const ref = firebase.firestore().collection("TaxAdvisor");
const db = firebase.firestore();

export default function IncomeTaxAdvisor() {
  //settings
  let tBracketSingle = [
    400000.0,
    100000.0,
    200000.0,
    1300000.0,
    90071992547409.0,
  ];
  let tBracketMarried = [
    450000.0,
    100000.0,
    200000.0,
    1250000.0,
    90071992547409.0,
  ];
  let tPercentage = [1, 10, 20, 30, 36, 36];

  //PF and CIT 33% or 300000 which ever is small.
  let maxPfCitPer = 33.3333; //33% of the total salary.
  let maxPfCit = 300000; //combination of PF and CIT per year
  let maxInsurance = 25000; //per year
  let maxMedicalInsurance = 20000; //per year

  //inputs
  let isSingle = true;
  let isMonthlyInput = true;
  let months = 12;
  let annualSalary = 0.0;

  let maxPfCitValue = 0.0;
  let maxPfCitPerValue = 0.0;

  //intermediate
  let totalDeduct = 0.0;
  let taxableSal = 0.0;
  let pf = 0.0;

  let usableCIT = 0.0;
  let bestCIT = 0.0;
  let bestInsurance = 0.0;
  let usableMedicalInsurance = 0.0;

  let newCalculation = {};
  let longLat = "";
  let location = "";

  //model for tax bracket tDetail
  class tDetail {
    constructor(bId = 0.0, bAmt = 0.0, bPer = 0.0, bSal = 0.0, bTax = 0.0) {
      this.bracketId = bId;
      this.bracketAmt = bAmt;
      this.bracketPer = bPer;
      this.bracketSal = bSal;
      this.bracketTax = bTax;
    }
  }

  //calculate tax brackets
  function calculateTaxBracket(tBracketS, tPer, taxableSalary) {
    let salDetails = [];
    let bSal = taxableSalary;
    for (let i = 0; i < tBracketS.length; i++) {
      let bracket = tBracketS[i];
      let bPer = tPer[i];
      let bt = 0.0;

      //cal bracket sal
      if (bSal <= bracket) {
        bt = (bPer / 100) * bSal;
        let b = new tDetail(i + 1, bracket, bPer, bSal, bt);
        salDetails.push(b);
        break;
      } else {
        bt = (bPer / 100) * bracket;
        let b = new tDetail(i + 1, bracket, bPer, bracket, bt);
        salDetails.push(b);
        bSal = bSal - bracket;
      }
    }
    return salDetails;
  }

  //calculate total tax
  function CalculateTotalTax(tBracketS, tPer, taxableSalary) {
    // console.log("issingle=" + isSingle);
    // console.log("bracket=" + tBracketS);
    let tDetails = calculateTaxBracket(tBracketS, tPer, taxableSalary);
    //console.log(tDetails.length);
    let totalTax = 0.0;
    tDetails.forEach((tDetail) => {
      totalTax = totalTax + tDetail.bracketTax;
      //console.log(tDetail)
    });
    return totalTax;
  }

  const [showPopup, setShowPopup] = useState(false);
  function handlePopup(isShow) {
    setShowPopup(isShow);
  }

  const [userCountry, setUserCountry] = useState("");
  const [userIp, setUserIp] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [userLongLat, setUsserLongLat] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("single");
  const [salaryType, setSalaryType] = useState("Monthly");
  const [salary, setSalary] = useState(0.0);
  const [bonus, setBonus] = useState(0.0);
  const [cit, setCIT] = useState(0.0);
  const [insurance, setInsurance] = useState(0.0);
  const [medicalInsurance, setMedicalInsurance] = useState(0.0);

  // const [salary, setSalary] = useState(100000.0);
  // const [bonus, setBonus] = useState(100000.0);
  // const [cit, setCIT] = useState(15000.0);
  // const [insurance, setInsurance] = useState(20000.0);

  const [monthcount, setMonthcount] = useState(12);
  const [taxableIncome, setTaxableIncome] = useState();
  const [taxYearly, setTaxYearly] = useState();

  const [pfPer, setPFPer] = useState(10.0);

  const [usableInsurance, setUsableInsurance] = useState(0.0);
  const [
    usableMedicalInsuranceAmount,
    setUsableMedicalInsuranceAmount,
  ] = useState(0.0);
  const [totalDeduction, setTotalDeduction] = useState();

  //new
  const [annualBasicSalary, setAnnualBasicSalary] = useState(0.0);
  const [PFContributionByEmployer, setPFContributionByEmployer] = useState(0.0);
  const [annualGrossSalary, setAnnualGrossSalary] = useState(0.0);
  const [usableCITAmount, setUsableCIT] = useState(0.0);
  const [bestCITAmount, setBestCIT] = useState(0.0);
  const [bestInsuranceAmount, setBestInsuranceAmount] = useState(0.0);
  const [totalDeductionAfterAdvice, setTotalDeductionAfterAdvice] = useState(
    0.0
  );

  function GetIntermediateValues() {
    annualSalary = isMonthlyInput ? parseFloat(salary) * months : salary;

    setAnnualBasicSalary(parseFloat(annualSalary));
    console.log("Inside GetIntermediateValues");
    console.log("isMonthlyInput: " + isMonthlyInput);
    console.log("salary input: " + salary);
    console.log("Annual Sal js: " + parseFloat(annualSalary));
    console.log("Annual Sal: " + annualSalary);

    //calculating pf
    pf = pfPer > 0 ? (pfPer / 100) * annualSalary : 0;

    console.log("pf %: " + pfPer);
    console.log("annualBasicSalary: " + parseFloat(annualBasicSalary));
    console.log("pfssss: " + parseFloat(pf));
    console.log("bonus: " + parseFloat(bonus));
    setPFContributionByEmployer(parseFloat(pf));

    let annualGrossSalary =
      parseFloat(annualSalary) + parseFloat(bonus) + parseFloat(pf);
    setAnnualGrossSalary(annualGrossSalary);
    console.log("Annual gross salary: " + annualGrossSalary);
    maxPfCitPerValue = (maxPfCitPer / 100) * annualGrossSalary;
  }

  function GetTotalDeductables() {
    GetPFCITMaxAmount();
    let totalPF = PFContributionByEmployer * 2;
    let totalDeductableAmount =
      parseFloat(usableCIT) +
      parseFloat(usableInsurance) +
      parseFloat(usableMedicalInsurance) +
      parseFloat(totalPF);
    console.log(
      "usablecit" +
        usableCIT +
        "usableInsurance" +
        usableInsurance +
        "totalPF" +
        PFContributionByEmployer
    );
    if (annualGrossSalary < totalDeductableAmount) {
      totalDeductableAmount = annualGrossSalary;
      bestInsurance = totalDeductableAmount - usableCIT - totalPF;
    } else {
      bestInsurance = maxInsurance;
    }

    setUsableCIT(usableCIT);
    setBestCIT(bestCIT);
    setBestInsuranceAmount(bestInsurance);
    return totalDeductableAmount;
  }

  function GetTotalDeductablesAfterAdvice() {
    let totalPF = PFContributionByEmployer * 2;

    let totalDeductableAmount =
      parseFloat(bestCIT) +
      parseFloat(maxInsurance) +
      parseFloat(usableMedicalInsurance) +
      parseFloat(totalPF);
    if (annualGrossSalary < totalDeductableAmount) {
      totalDeductableAmount = annualGrossSalary;
      bestInsurance = totalDeductableAmount - bestCIT - totalPF;
    } else {
      bestInsurance = maxInsurance;
    }
    setUsableCIT(usableCIT);
    setBestCIT(bestCIT);
    setBestInsuranceAmount(bestInsurance);
    return totalDeductableAmount;
  }

  function showCalculation() {
    if (salary <= 0 || isNaN(salary) || salary.length == 0) {
      alert("Please enter your Salary.");
      return;
    }

    if (bonus < 0 || isNaN(bonus) || bonus.length == 0) {
      alert("Please enter your Bonus.");
      return;
    }

    if (cit < 0 || isNaN(cit) || cit.length == 0) {
      alert("Please enter proper CIT.");
      return;
    }

    if (insurance < 0 || isNaN(insurance) || insurance.length == 0) {
      alert("Please enter proper Insurance amount.");
      return;
    }

    if (
      medicalInsurance < 0 ||
      isNaN(medicalInsurance) ||
      medicalInsurance.length == 0
    ) {
      alert("Please enter proper Medical Insurance amount.");
      return;
    }

    setShowPopup(true);
    isMonthlyInput = salaryType == "Monthly" ? true : false;
    isSingle = maritalStatus == "single" ? true : false;
    GetAdvice();
    pushCalculation(newCalculation);

    // if (taxYearly - taxAfterAdvice < 1) {
    //   topMessage = <h5 className="greenText">Please read the advices</h5>;
    // } else {
    //   topMessage = (
    //     <h5 className="greenText">
    //       Follow the following advices and you will save (
    //       {parseFloat(taxYearly).toFixed(1)}-
    //       {parseFloat(taxAfterAdvice).toFixed(1)}) = Rs.{" "}
    //       {(parseFloat(taxYearly) - parseFloat(taxAfterAdvice)).toFixed(1)}{" "}
    //       yearly.
    //     </h5>
    //   );
    // }
  }

  //firebase saving
  function pushCalculation(newCalculation) {
    console.log("inside pushCalculation(), tax yearly", taxYearly);
    let nowDate = new Date();
    let id =
      nowDate.getFullYear() +
      "" +
      nowDate.getMonth() +
      "" +
      nowDate.getDate() +
      "" +
      nowDate.getHours() +
      "" +
      nowDate.getMinutes();

    // console.log(newCalculation);
    ref
      .doc(id)
      .set(newCalculation)
      .then(() => {
        console.log("tax calculation added to db");
      });
  }

  function GetAdvice() {
    //insurance
    let uInsurance = insurance > maxInsurance ? maxInsurance : insurance;
    if (uInsurance > annualGrossSalary) {
      let totalPf = PFContributionByEmployer * 2;
      uInsurance = annualGrossSalary - totalPf - usableCITAmount;
    }
    setUsableInsurance(uInsurance);

    //medical insurance
    usableMedicalInsurance = medicalInsurance;
    if (medicalInsurance > maxMedicalInsurance) {
      usableMedicalInsurance = maxMedicalInsurance;
    }

    setUsableMedicalInsuranceAmount(usableMedicalInsurance);
    annualSalary = isMonthlyInput ? parseFloat(salary) * months : salary;
    console.log("Annual Sal ajs: " + parseFloat(annualSalary));
    console.log("_______________________________________________________");
    console.log("usableInsurance" + usableInsurance);
    GetIntermediateValues();
    totalDeduct = GetTotalDeductables();
    setTotalDeduction(totalDeduct);
    console.log("Grosssss" + annualGrossSalary);
    taxableSal = parseFloat(annualGrossSalary) - parseFloat(totalDeduct);
    setTaxableIncome(taxableSal);
    console.log("---------------------------------");
    console.log("Current Taxation");
    console.log("--------------------------------");
    console.log("Total Salary (TS): " + annualGrossSalary);
    console.log("Usable cit " + usableCIT);
    console.log("Total Deductables (TD): " + totalDeduct);
    console.log("Taxable Salary (TS-TD): " + taxableIncome);
    console.log("--------------------------------");
    let taxAmount = CalculateTotalTax(
      isSingle ? tBracketSingle : tBracketMarried,
      tPercentage,
      taxableSal
    );
    setTaxYearly(taxAmount);
    let monthlyTax = taxAmount / 12;
    console.log("Total Tax: " + taxAmount);
    console.log("Total Tax per month: " + monthlyTax);
    console.log("--------------------------------");
    console.log("--------------------------------");

    console.log("Taxation after advice implemneted");
    console.log("--------------------------------");
    let totalDeductAfterAdvice = GetTotalDeductablesAfterAdvice();
    setTotalDeductionAfterAdvice(totalDeductAfterAdvice);
    let taxableSalAfterAdvice =
      parseFloat(annualGrossSalary) - parseFloat(totalDeductAfterAdvice);
    setTaxableSalAfterAdviceImp(taxableSalAfterAdvice);
    console.log("Total Salary (TS): " + annualGrossSalary);
    console.log("Total Deductables (TD): " + totalDeductionAfterAdvice);
    console.log("Taxable Salary (TS-TD): " + taxableSalAfterAdviceImp);
    console.log("--------------------------------");
    let taxAmountAfterAdvice = CalculateTotalTax(
      isSingle ? tBracketSingle : tBracketMarried,
      tPercentage,
      taxableSalAfterAdvice
    );
    setTaxAfterAdvice(taxAmountAfterAdvice);
    let monthlyTaxAfterAdvice = taxAmountAfterAdvice / 12;
    console.log("Total Tax: " + taxAmountAfterAdvice);
    console.log("Total Tax per month: " + monthlyTaxAfterAdvice);
    console.log("--------------------------------");
    console.log("Monthly Tax saved: " + (monthlyTax - monthlyTaxAfterAdvice));

    let taxAmountAfterAdviceAndMarry = 0.0;
    if (isSingle) {
      console.log("If you get married.");
      console.log("--------------------------------");
      taxAmountAfterAdviceAndMarry = CalculateTotalTax(
        tBracketMarried,
        tPercentage,
        taxableSalAfterAdvice
      );
      setTaxAfterAdviceAndMarriage(taxAmountAfterAdviceAndMarry);
      let monthlyTaxAfterAdviceAndMarry = taxAmountAfterAdviceAndMarry / 12;
      console.log(
        "Total Tax after getting married: " + taxAmountAfterAdviceAndMarry
      );
      console.log("Total Tax per month: " + monthlyTaxAfterAdviceAndMarry);
      console.log("--------------------------------");
      console.log(
        "Monthly Tax saved: " + (monthlyTax - monthlyTaxAfterAdviceAndMarry)
      );
    }
    console.log("Tip of the day: You can take loan from CIT");

    //for sending data firebase

    newCalculation = {
      userLongLat,
      userLocation,
      userCountry,
      salaryType,
      maritalStatus,
      salary,
      bonus,
      months,
      annualGrossSalary,
      pfPer,
      PFContributionByEmployer,
      cit,
      lifeInsurance: insurance,
      healthInsurance: 0,
      donation: 0,
      totalDeduct,
      totalDeductAfterAdvice,
      taxableSal,
      taxableSalAfterAdvice,
      taxAmount,
      taxAmountAfterAdvice,
      taxAmountAfterAdviceAndMarry,
      from: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userIp,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
    };
  }

  function GetInsuranceMaxAmount() {
    if (insurance == maxInsurance) {
      setAdvice2("Insurance premium of Rs. " + maxInsurance + " is perfect");
    } else if (insurance > maxInsurance) {
      setAdvice2(
        "Insurance premium of Rs. " +
          maxInsurance +
          " is only usable for tax exmption"
      );
    } else {
      let diff = parseFloat(maxInsurance) - parseFloat(insurance);
      setAdvice2("Increase Insurance premium by Rs. " + diff.toFixed(1));
    }
  }

  function GetPFCITMaxAmount() {
    let totalPF = PFContributionByEmployer * 2;
    let combination = parseFloat(totalPF) + parseFloat(cit);
    let is33PerUsed = false;
    console.log("PF and CIT combine: " + combination);
    //inital prep
    maxPfCitValue = maxPfCit;
    if (maxPfCitValue > maxPfCitPerValue) {
      maxPfCitValue = maxPfCitPerValue;
      is33PerUsed = true;
    }
    console.log("choosed maxPFCIT :" + maxPfCitValue);

    if (combination == maxPfCitValue) {
      console.log("You have perfect amount of CIT");
      usableCIT = cit;
      bestCIT = cit;
      setAdvice1("You have perfect amount of CIT");
      //setUsableCIT(cit);
    } else if (combination > maxPfCitValue) {
      //user is putting more than required amount in CIT
      usableCIT = maxPfCitValue - totalPF;
      bestCIT = usableCIT;
      //setUsableCIT(maxPfCitValue - totalPF);
      let excessCIT = combination - maxPfCitValue;
      if (is33PerUsed) {
        console.log(
          "Your PF + CIT is more than 1/3 of your gross annual salary ie. RS. " +
            maxPfCitValue +
            ". Decrease CIT by Rs. " +
            excessCIT.toFixed(1)
        );
        setAdvice1(
          "Your PF + CIT is more than 1/3 of your gross annual salary ie. RS. " +
            maxPfCitValue +
            ". Decrease CIT by Rs. " +
            excessCIT.toFixed(1)
        );
      } else {
        console.log(
          "Your PF + CIT is more than Rs. " +
            maxPfCitValue +
            ". Decrease CIT by Rs. " +
            excessCIT.toFixed(1)
        );
      }
      setAdvice1(
        "Your PF + CIT is more than Rs. " +
          maxPfCitValue +
          ". Decrease CIT by Rs. " +
          excessCIT.toFixed(1)
      );
    } else {
      //user is putting less than required amount in CIT
      usableCIT = cit;
      bestCIT = maxPfCitValue - totalPF;
      //setUsableCIT(cit);
      let shortCIT = maxPfCitValue - combination;
      // alert(combination + ";" + maxPfCitValue);
      if (is33PerUsed) {
        console.log(
          "Your PF + CIT is less than 1/3 of your gross annual salary ie. Rs. " +
            parseFloat(maxPfCitValue).toFixed(1) +
            ". Increase CIT by Rs. " +
            shortCIT.toFixed(1)
        );
        setAdvice1(
          "Your PF + CIT is less than 1/3 of your gross annual salary ie. Rs. " +
            parseFloat(maxPfCitValue).toFixed(1) +
            ". Increase CIT by Rs. " +
            shortCIT.toFixed(1)
        );
      } else {
        console.log(
          "Your PF + CIT is less than Rs. " +
            maxPfCitValue +
            ". Increase CIT  by Rs. " +
            shortCIT.toFixed(1)
        );
        setAdvice1(
          "Your PF + CIT is less than Rs. " +
            maxPfCitValue +
            ". Increase CIT  by Rs. " +
            shortCIT
        );
      }
    }
    return maxPfCitValue;
  }

  //mewenmd

  //adjustments
  const [taxableSalAfterAdviceImp, setTaxableSalAfterAdviceImp] = useState(0.0);
  const [taxAfterAdvice, setTaxAfterAdvice] = useState(0.0);
  const [taxAfterAdviceAndMarriage, setTaxAfterAdviceAndMarriage] = useState(
    0.0
  );
  const [advice1, setAdvice1] = useState("");
  const [advice2, setAdvice2] = useState("");

  function httpGet(theUrl) {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, false); // false for synchronous request
    xmlHttp.send(null);
    return xmlHttp.responseText;
  }

  function clearAll() {
    // setMaritalStatus("single");
    setSalary(0.0);
    setBonus(0.0);
    setMonthcount(12);
    setPFPer(10.0);
    setCIT(0.0);
    setInsurance(0.0);
    setTotalDeduction(0.0);
    setAnnualGrossSalary(0.0);
  }

  useEffect(() => {
    console.log("sal changed1");
    isMonthlyInput = salaryType == "Monthly" ? true : false;
    GetAdvice();
    GetInsuranceMaxAmount();
    return () => {};
  }, [salary, bonus, pfPer, cit, insurance, months, salaryType]);

  useEffect(() => {
    // fetch(
    //   "https://api.ipdata.co?api-key=f4b95b23c6fd14b5e7cdec941323fa4ae3659db3fe6e0e5c6f9bc874"
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log("User data pull complete");
    //     longLat = res.latitude + "," + res.longitude;
    //     location = res.region + ", " + res.city;
    //     setUserCountry(res.country_name);
    //     setUserLocation(location);
    //     setUsserLongLat(longLat);
    //     setUserIp(res.ip);
    //   });

    // Google add scense script added
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6780615883898279";
    script.async = true;
    document.body.appendChild(script);

    return () => {};
  }, []);

  return (
    <>
      {/* Popup */}
      <Modal
        show={showPopup}
        onHide={handlePopup}
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Body>
          {/* tst */}
          <img
            src={icoClose}
            alt="Close Button"
            class="closeBtn"
            onClick={() => {
              setShowPopup(false);
            }}
          />
          <div class="row">
            <div class="col-sm">
              {totalDeductionAfterAdvice - totalDeduction > 0 ? (
                <h5 className="greenText padding10 text-justify">
                  These advice will help you save (
                  {parseFloat(taxYearly).toFixed(1)}-
                  {parseFloat(taxAfterAdvice).toFixed(1)}) = Rs.{" "}
                  {(parseFloat(taxYearly) - parseFloat(taxAfterAdvice)).toFixed(
                    1
                  )}{" "}
                  yearly.
                </h5>
              ) : (
                <h5 className="greenText padding10 text-justify">
                  Follow these advices for proper use of money.
                </h5>
              )}
            </div>
          </div>
          <div class="row row-bordered">
            <div class="col-sm">
              <ul>
                <li>{advice1}.</li>
                <li>{advice2}.</li>

                {totalDeductionAfterAdvice - totalDeduction > 0 ? (
                  <li>
                    Cash in hand will decrease by{" "}
                    {parseFloat(
                      totalDeductionAfterAdvice - totalDeduction
                    ).toFixed(1)}{" "}
                    but this will transfer to savings like CIT and Insurance.
                  </li>
                ) : (
                  <p></p>
                )}
              </ul>
            </div>
          </div>
          <div class="row border-right">
            <div class="col-sm">
              <div class="row text-center border-right">
                <div class="col-sm">
                  <h5 class="greenText">Current Tax</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-sm">
                  {/* Current Tax Table*/}
                  <table id="productSizes" class="table">
                    <tbody>
                      <tr class="d-flex">
                        <td class="col-8">Yearly Salary :</td>
                        <td class="col-4 text-right">
                          {annualBasicSalary.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Yearly Bonus :</td>
                        <td class="col-4 text-right">
                          +{parseFloat(bonus).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">PF added by Employer {pfPer}% :</td>
                        <td class="col-4 text-right">
                          {" "}
                          +{PFContributionByEmployer.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8 greenText padding10 text-right font-weight-bold">
                          Total Yearly Income A :
                        </td>
                        <td class="col-4 greenText padding10 text-right font-weight-bold">
                          {annualGrossSalary.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">PF {pfPer * 2}% :</td>
                        <td class="col-4 text-right">
                          -{(PFContributionByEmployer * 2).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Usable CIT :</td>
                        <td class="col-4 text-right">
                          {" "}
                          -{parseFloat(usableCITAmount).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Usable Life Insurance :</td>
                        <td class="col-4 text-right text-right">
                          {" "}
                          -{parseFloat(usableInsurance).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Usable Medical Insurance :</td>
                        <td class="col-4 text-right text-right">
                          {" "}
                          -{parseFloat(usableMedicalInsuranceAmount).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex font-weight-bold">
                        <td class="col-8 greenText padding10 text-right font-weight-bold">
                          Total Deductibles B :
                        </td>
                        <td class="col-4 greenText padding10 text-right">
                          -{parseFloat(totalDeduction).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8 greenText padding10 text-right font-weight-bold">
                          {" "}
                          Taxable Income A-B :
                        </td>
                        <td class="col-4 greenText padding10 text-right font-weight-bold">
                          {" "}
                          {parseFloat(taxableIncome).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Yearly Tax :</td>
                        <td class="col-4 text-right">
                          {parseFloat(taxYearly).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8"> Monthly Tax :</td>
                        <td class="col-4 text-right">
                          {" "}
                          {(taxYearly / 12).toFixed(1)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-sm">
              <div class="row text-center">
                <div class="col-sm">
                  <h5 class="greenText">Tax after Advice</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-sm">
                  {/* After Advice Table*/}
                  <table id="productSizes" class="table">
                    <tbody>
                      <tr class="d-flex">
                        <td class="col-8">Yearly Salary :</td>
                        <td class="col-4 text-right">
                          {annualBasicSalary.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Yearly Bonus :</td>
                        <td class="col-4 text-right">
                          +{parseFloat(bonus).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">PF added by Employer {pfPer}% :</td>
                        <td class="col-4 text-right">
                          {" "}
                          +{PFContributionByEmployer.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8 greenText padding10 text-right font-weight-bold">
                          Total Yearly Income A :
                        </td>
                        <td class="col-4 greenText padding10 text-right font-weight-bold">
                          {annualGrossSalary.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">PF {pfPer * 2}% :</td>
                        <td class="col-4 text-right">
                          -{(PFContributionByEmployer * 2).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">
                          {" "}
                          CIT ({"Added "}
                          {parseFloat(bestCITAmount - usableCITAmount).toFixed(
                            1
                          )}
                          ) :
                        </td>
                        <td class="col-4 text-right">
                          {" "}
                          -{parseFloat(bestCITAmount).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Life Insurance :</td>
                        <td class="col-4 text-right text-right">
                          {" "}
                          -{parseFloat(bestInsuranceAmount).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Medical Insurance :</td>
                        <td class="col-4 text-right text-right">
                          {" "}
                          -{parseFloat(usableMedicalInsuranceAmount).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8 greenText padding10 text-right font-weight-bold">
                          Total Deductibles B :
                        </td>
                        <td class="col-4 greenText padding10 text-right font-weight-bold">
                          -{parseFloat(totalDeductionAfterAdvice).toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8 greenText padding10 text-right font-weight-bold">
                          {" "}
                          Taxable Income A-B :
                        </td>
                        <td class="col-4 greenText padding10 text-right font-weight-bold">
                          {" "}
                          {taxableSalAfterAdviceImp.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8">Yearly Tax :</td>
                        <td class="col-4 text-right">
                          {taxAfterAdvice.toFixed(1)}
                        </td>
                      </tr>
                      <tr class="d-flex">
                        <td class="col-8"> Monthly Tax :</td>
                        <td class="col-4 text-right">
                          {" "}
                          {(taxAfterAdvice / 12).toFixed(1)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {maritalStatus == "single" &&
          taxableSalAfterAdviceImp > tBracketSingle[0] ? (
            <div class="row">
              <div class="col-sm greenText text-justify">
                If you get married and follow the advice, your tax will be Rs.{" "}
                {parseFloat(taxAfterAdviceAndMarriage).toFixed(1)} and you will
                save Rs.{" "}
                {parseFloat(taxYearly - taxAfterAdviceAndMarriage).toFixed(1)}{" "}
                from current tax.
              </div>
            </div>
          ) : (
            <p></p>
          )}
          <Button
            className="normalBtn onlymobile"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
      {/* Popup End*/}

      <Navbar />
      <div className="body-wrapper container">
        {/* add sense */}
        {/* <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-9851940040137033"
          data-ad-slot="12121212"
          data-ad-format="auto"
        /> */}

        {/* add sense end */}

        <div className="addTop greenText padding10">
          <h5 className="greenText padding10 text-center">
            {" "}
            This is not a professional accouning site. Please consult with your
            Accountant to verify the advice.{" "}
          </h5>
        </div>
        <div className="normalcard">
          {" "}
          <div class="row">
            <div class="col-sm">
              <h2 className="greenText padding10 text-center">
                Free Tax Advisor Nepal (Beta Version)
              </h2>
            </div>
            {/* <div class="col-sm">
              <a onClick={() => alert("This option is under constrution.")}>
                <strong>Advance Option</strong>
              </a>
            </div> */}
          </div>
          {/* <strong>Helps you find the ways to save the tax.</strong> */}
          <div className="form">
            {/* Annual Salary */}
            <div class="row">
              <div class="col-sm">
                <h6 class="row formHeader">Marital Status</h6>
                <select
                  className="formElement row"
                  value={maritalStatus}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                >
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                </select>
              </div>
              <div class="col-sm">
                <h6 class="row formHeader">Salary Type</h6>
                <select
                  className="formElement row"
                  value={salaryType}
                  onChange={(e) => setSalaryType(e.target.value)}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="yearly">Yearly Total</option>
                </select>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sm">
                <h5>
                  <strong>Annual Income</strong>
                </h5>
                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">
                      {salaryType == "Monthly"
                        ? "Monthly Salary (Sal)"
                        : "Total salary of this year (Sal)"}
                    </h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={salary}
                      onChange={(e) => setSalary(e.target.value)}
                      required={true}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">Total Bonus of this year (B)</h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={bonus}
                      onChange={(e) => setBonus(e.target.value)}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">Months</h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={monthcount}
                      disabled={true}
                      // onChange={(e) => setMonths(12)}
                      readonly
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">
                      Total Salary (Yearly Salary + B + PF)
                    </h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={annualGrossSalary}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm">
                {/* annual deduction */}
                <h5>
                  <strong>Annual Deduction</strong>
                </h5>
                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">
                      Employees Provident Fund percentage (PF %)
                    </h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={pfPer}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (value > 100) {
                          alert("Percentage cannot be more than 100%");
                        } else {
                          setPFPer(value);
                        }
                        console.log(pfPer);
                      }}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">
                      Citizen Investment Trust of this year (CIT)
                    </h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={cit}
                      onChange={(e) => setCIT(e.target.value)}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">
                      Life Insurance Premium of this year (I)
                    </h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={insurance}
                      onChange={(e) => setInsurance(e.target.value)}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">Medical Insurance (MI)</h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={medicalInsurance}
                      onChange={(e) => setMedicalInsurance(e.target.value)}
                    />
                  </div>
                </div>

                {/* <div class="row">
                  <div class="col-sm">
                    <h6 class="row formHeader">Donation (max 100000 or 5%)</h6>
                    <input
                      className="formElement row"
                      type="number"
                      value={cit + insurance}
                      onChange={(e) =>
                        setTotalDeduction(
                          parseFloat(pf) + parseFloat(cit) + insurance
                        )
                      }
                      disabled={true}
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <br />
            {/* Submit */}
            <div class="row">
              <div class="col-sm">
                {/* <Button
                  className="normalBtn"
                  onClick={() => setShowPopup(true)}
                >
                  Calculate & Advise
                </Button> */}
                <Button className="normalBtn" onClick={() => showCalculation()}>
                  Calculate & Advise
                </Button>
              </div>
              <div class="col-sm">
                <Button className="normalBtn" onClick={() => clearAll()}>
                  Clear All
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>.</div>
        <div class="row">
          <div class="col-sm">
            <h5 class="greenText padding10">Useful Informations</h5>
            <ul>
              <li>For Non Resident, tax is flat 25% of Taxable income.</li>
              <li>You can take loan through your PF or CIT account.</li>
              <li>
                Your PF or CIT deposit amount is tax free up to Rs. 5,00,000.
                Greater amount part will be taxed at 5%.
              </li>
              <li>
                When you go to withdraw PF or CIT, usually you cannot withdraw
                last months deposit. Ask about it so you do not forget it.
              </li>
              <li>Life Insurance allowed is Rs. 25000 per year.</li>
              <li>Maximum Health Insurance allowed is Rs. 20,000 per year.</li>
              <li>
                Maximum Donation allowed is Rs. 1,00,000 or 5% of Adjusted
                Taxable Income.
              </li>
              <li>
                Female tax payer will get rebate of 10% of total tax payable.
              </li>
              <li>
                Full Detail:{" "}
                <a
                  target="_blank"
                  href="https://ird.gov.np/public/pdf/964678748.pdf"
                >
                  {" "}
                  https://ird.gov.np/
                </a>
              </li>
            </ul>
          </div>
        </div>
        <LeaderBoardAd />
      </div>

      <Footer />
    </>
  );
}
