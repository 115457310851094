import "./AboutMe.css";
import profilePic from "../Images/ProfilePic.jpeg";
import personalityPic from "../Images/Personality.png";
import skillPic from "../Images/TecKnow.png";
import projectMgmt from "../Images/ProjectMgmt.png";
import HeroSection from "../Components/HeroSection";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faPinterest,
  faInstagram,
  faGithub,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import {
  faCameraRetro,
  faLaptop,
  faLaptopCode,
  faPlane,
} from "@fortawesome/free-solid-svg-icons";

const AboutMe = () => {
  return (
    <div className="row">
      <div className="col-lg-3 nopaddingcard">
        {/* Profile Picture */}
        <div className="profilecard">
          <img src={profilePic} alt="Profile pic" className="profilepic" />
          <div className="profileDetail">
            <p>
              <h5>
                <strong>MANOJ SHRESTHA</strong>
              </h5>
              Mobile | Web App Developer
              <br />
              in Tokyo City
              <br />
              Last Updated : June 2023
              <div className="socialContainer">
                <a
                  href="https://www.linkedin.com/in/shrestha-manoj/"
                  target="_blank"
                  class="outerLink"
                >
                  <FontAwesomeIcon icon={faLinkedin} className="socialIcon" />
                </a>
                <a
                  href="https://github.com/manojshrestha"
                  target="_blank"
                  class="outerLink"
                >
                  <FontAwesomeIcon icon={faGithub} className="socialIcon" />
                </a>
                <a
                  href="https://www.instagram.com/manojshrestha/"
                  target="_blank"
                  class="outerLink"
                >
                  <FontAwesomeIcon icon={faInstagram} className="socialIcon" />
                </a>
                <a
                  href="https://www.pinterest.com/manoj0491/_saved/"
                  target="_blank"
                  class="outerLink"
                >
                  <FontAwesomeIcon icon={faPinterest} className="socialIcon" />
                </a>
                <a
                  href="https://gurushots.com/manoj.shrestha.72551/photos"
                  target="_blank"
                  class="outerLink"
                >
                  <FontAwesomeIcon
                    icon={faCameraRetro}
                    className="socialIcon"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCha0HGPIRgf2zIIhh1owN2w"
                  target="_blank"
                  class="outerLink"
                >
                  <FontAwesomeIcon icon={faYoutube} className="socialIcon" />
                </a>
              </div>
              {/* <button
                onClick={alert("Please email @ iam.manojshrestha@gmail.com")}
              >
                Download Resume
              </button> */}
            </p>
            {/* <Button>Download Resume</Button> */}
          </div>
        </div>
      </div>
      <div class="col-lg-9 nopaddingcard">
        <div className="card">
          <HeroSection />
          <br></br>
          <h5>
            <strong>Who am I ?</strong>
          </h5>
          <p>
            {/* <strong>10+</strong> years of experience as a software engineer,
            currently working as Associate Engineering Manager in a software
            company (
            <a href="https://deerhold.org/japan/" target="_blank">
              Deerhold Japan
            </a>
            ). Capable of working with a variety of technology and software
            solutions for both Mobile and Web Applications. Also worked as
            Trainer for Mobile Application Development. */}
            With over 10 years of experience as a seasoned software engineer, my
            expertise extends to a wide range of technologies and software
            solutions for both Mobile and Web Applications. Additionally, I have
            had the opportunity to share my knowledge and expertise as a Trainer
            for iOS Application Development. I am passionate about delivering
            high-quality solutions and constantly staying updated with the
            latest advancements in the industry.
          </p>
          <h5>
            <strong>What I like</strong>
          </h5>
          <ul style={{ listStyleType: "none", paddingInlineStart: "20px" }}>
            <li>
              <FontAwesomeIcon icon={faLaptopCode} className="icon" />
              Software Development.
            </li>
            <li>
              <FontAwesomeIcon icon={faLaptop} className="icon" />
              New technology (Computer, Mobiles and all crazy gadgets).
            </li>
            <li>
              <FontAwesomeIcon icon={faPlane} className="icon" />
              Traveling
            </li>
            <li>
              <FontAwesomeIcon icon={faCameraRetro} className="icon" />
              My camera and taking picture.
            </li>
          </ul>

          <p>
            <h5>
              {" "}
              <strong>What I can do for you</strong>
            </h5>
            <ul>
              <li>
                <strong> Mobile App Development</strong> iOS Native (iWatch App
                | iPhone App | iPad App), React Native, Phonegap.
              </li>
              <li>
                <strong>Web App Development</strong> (React using AWS /
                Firebase) eg. this site is developmed using React, Bootstrap and
                Firebase.
              </li>
              <li>
                <strong>IT Consulting</strong>
                <br />
                <ul>
                  <li>
                    Technical strategy - How to get started on building a new
                    product?
                  </li>
                  <li>
                    System upgrade - How to migrate Legacy system to a modern
                    efficient one.
                  </li>
                  <li>
                    Technical suggestion - How to get the best deal from your
                    software vendor or developer.
                  </li>
                </ul>
              </li>
              <li>
                <strong> Training</strong> <br />
                <ul>
                  <li>Mobile Application Development (iOS / React Native)</li>
                  <li>Software development process</li>
                </ul>
              </li>
            </ul>
          </p>

          <div className="row">
            <div className="col-lg-4 nopaddingcard">
              <img
                src={personalityPic}
                alt="Personality Bar"
                className="imageContainer"
              />
            </div>

            <div className="col-lg-4 nopaddingcard">
              <img src={skillPic} alt="Skill Bar" className="imageContainer" />
            </div>

            <div className="col-lg-4 nopaddingcard">
              <img src={projectMgmt} alt="PM" className="imageContainer" />
            </div>
          </div>

          <p></p>
          <p>
            <h5>
              {" "}
              <strong>Volunteer Experience</strong>
            </h5>
            <ul>
              <li>
                <strong>
                  NUS-MIT Datathon 2020 - Singapore (AI expo and Datathon)
                </strong>
                <ul>
                  <li>
                    I was a member of Team Japan. Our teams topic was{" "}
                    <strong>
                      "Machine learning approach to predict positive screening
                      of methicillin-resistant staphylococcus aureus during
                      mechanical ventilation"
                    </strong>{" "}
                    . We used ICU clinical from MIMIC (non-omop) and eICU
                    (non-omop).
                  </li>
                  <li>
                    {" "}
                    My role was to manage the team, set up processes to
                    facilitate smooth communication between the medical team and
                    IT team, and assist in the AI aspect of data analysis.
                  </li>
                  <li>We utilized Google TensorFlow for AI.</li>
                  <li>
                    Official website: 
                    <a href="https://www.nus-datathon.com/2020" target="_blank">
                      https://www.nus-datathon.com/2020
                    </a>
                  </li>
                  <li>
                    Published Journal: 
                    <a
                      href="https://www.frontiersin.org/articles/10.3389/fmed.2021.694520/full?&utm_source=Email_to_authors_&utm_medium=Email&utm_content=T1_11.5e1_author&utm_campaign=Email_publication&field=&journalName=Frontiers_in_Medicine&id=694520"
                      target="_blank"
                    >
                      Frontiers link...
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </p>

          {/* <div className="row">
            <div className="col-lg-4 nopaddingcard">
              {" "}
              <h5>Personlaity </h5>
              <ul>
                <li>Midful - Inattentive </li>
                <li>Available - Busy </li>
                <li>Inthusiastic - Apathic </li>
                <li>Thinking - Feeling</li>
                <li>Introvert - Extrovert</li>
              </ul>
            </div>
            <div className="col-lg-4 nopaddingcard">
              <h5>Project Management</h5>
              <ul>
                <li>Critical thinking 80</li>
                <li>Public speaking 60</li>
                <li>Taking initiative 80</li>
                <li>Leadership 80</li>
                <li>Teamwork 90</li>
              </ul>{" "}
            </div>
            <div className="col-lg-4 nopaddingcard">
              {" "}
              <h5>Tech Knowledge</h5>
              <ul>
                <li>SDLC 80</li>
                <li>Mobile Apps 85</li>
                <li>Web Technology 75</li>
                <li>DevOps 60</li>
                <li>Tech news 85</li>
                <li>Social Network 70</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
