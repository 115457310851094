// import firebase from "firebase/app"
// import "firebase/storage"

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB1dzRUoGpwjtpXtatiSyqaZznFAWC1Roc",
//   authDomain: "manojgallary-d7e7f.firebaseapp.com",
//   databaseURL: "https://manojgallary-d7e7f-default-rtdb.firebaseio.com",
//   projectId: "manojgallary-d7e7f",
//   storageBucket: "manojgallary-d7e7f.appspot.com",
//   messagingSenderId: "778884346623",
//   appId: "1:778884346623:web:7d61784cf92c2f682a52ca",
//   measurementId: "G-BFRL2W1J2W"
// };

//   var app = firebase.initializeApp(firebaseConfig);

//   const storage = firebase.storage();
//   export {storage, firebase as default}

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB1dzRUoGpwjtpXtatiSyqaZznFAWC1Roc",
  authDomain: "manojgallary-d7e7f.firebaseapp.com",
  databaseURL: "https://manojgallary-d7e7f-default-rtdb.firebaseio.com",
  projectId: "manojgallary-d7e7f",
  storageBucket: "manojgallary-d7e7f.appspot.com",
  messagingSenderId: "778884346623",
  appId: "1:778884346623:web:7d61784cf92c2f682a52ca",
  measurementId: "G-BFRL2W1J2W",
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
export { storage, firebase as default };
